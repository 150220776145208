<template>
  <div>
    <el-card>
      <div slot="header">
        <span class="title-text">我的消息</span>
      </div>
      <div>
        <button @click="backToInfo" class="back-btn">&lt; 返回</button>
        <div v-if="$route.query.noticeType !== '7'">
          <div class="content-title">{{ messageDetail.title }}</div>
          <div class="content-time">
            <span>{{ messageDetail.createTime }}</span>
            <span @click="deleteDetail" style="margin-left: 15px; cursor: pointer">删除</span>
          </div>
          <div class="content">{{ messageDetail.content }}</div>
        </div>
        <div v-else>
          <div class="content-title">{{ otherContent.title }}</div>
          <div class="content-time">
            <span>{{ $route.query.createTime }}</span>
            <span @click="deleteDetail" style="margin-left: 15px; cursor: pointer">删除</span>
          </div>
          <div v-html="otherContent.questionnaireAndOthers" class="content"></div>
        </div>
        <!-- <button class="goto-org" @click="goToOrg">进入服务机构</button> -->
      </div>
    </el-card>
  </div>
</template>

<script>
import { request } from '../../../../network';
import { getMemberId } from '@/assets/public/utils/token';

export default {
  name: 'messageDetail',
  data () {
    return {
      messageDetail: {
        createTime: '',
        id: 2,
        title: '',
        content: ''
      },
      otherContent: {}
    };
  },
  created () {
    if (this.$route.query.noticeType === '7') {
      this.getContent(); 
    } else {
      this.getFindNoticeById();
    }
  },
  methods: {
    async getFindNoticeById () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/findNoticeById',
        params: {
          id: this.$route.query.id
        }
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      this.messageDetail = res.data;
    },
    backToInfo () {
      this.$router.push({
        path: '/personal/info'
      });
    },
    async deleteDetail () {
      const { data: res } = await request({
        method: 'DELETE',
        url: '/pcp/PersonalCenter/deleteNoticeById',
        params: {
          id: this.$route.query.id
        }
      });
      if (res.code !== 200) return this.$message.error('删除失败');
      this.$message.success('删除成功');
      this.$router.push({
        path: '/personal/info'
      });
    },
    goToOrg () {
      this.$router.push({ path: '/member', query: { keyindex: '2' } });
    },
    async getContent () {
      const { data: res } = await request({
        method: 'GET',
        url: '/pcp/PersonalCenter/getNoticeInfo',
        params: {
          memberId: getMemberId(),
          noticeId: this.$route.query.fieldId,
          noticeType: 7,
          pageNo: 1,
          pageSize: 10
        }
      });
      if (res.code !== 200) return this.$message.error('信息获取失败');
      this.otherContent = res.data;
    },
  }
};
</script>

<style scoped>
.title-text {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
.back-btn {
  margin-bottom: 30px;
  width: 60px;
  height: 34px;
  background: #FFFFFF;
  border: 1px solid #DCDCDC;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}
.content-title {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
  color: #0E050B;
}
.content-time {
  margin-bottom: 30px;
  font-size: 14px;
  color: #9E9E9E;
}
.content {
  margin-bottom: 25px;
  font-size: 16px;
  color: #5D5A5C;
}
.goto-org {
  margin-bottom: 100px;
  width: 140px;
  height: 48px;
  background: #EBF3FF;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #156ED0;
}
</style>
